header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 60px;
    background-color: black;
    color: white;

}

.logo{

    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
    color: white;
    font-weight: 800;
}

.favoritos{

    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
    color: black;
    font-weight: 800;
    padding: 5px 14px;
    background-color: white;
    border-radius: 4px;
}